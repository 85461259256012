<template>
  <div class="row m-0" style="padding-bottom: 100px !important;">
    <div class="create-data-title text-start">Registro de emisora</div>
    <div class="pl-2 pr-2">
      <div class="create-data-subtitle mt-4 text-start">Datos de tu emisora</div>
    </div>
    <va-form ref="form">
      <div class="row m-0 justify-content-center mb-5 pb-5">
        <input id="cargararchivo" type="file" class="register-file-opacity" @change="showImageFile()">
        <div v-if="!imgUploads">
          <div class="register-token-right-subtitle text-start mt-3">Sube el logo de la emisora (Opcional)</div>
          <label for="cargararchivo" class="register-file-upload">
            <div class="register-file-upload-white">
              <div class="register-file-container">
                <img :src="ImgFile" alt="" />
                <div class="register-file-title">Formato JPG, PNG <br /> Peso máximo 300kb</div>
                <div class="d-flex justify-content-center container-inputs-vuestic text-center mb-0">
                  <div class="border mt-2">Subir imagen</div>
                </div>
                <div class="register-token-right-subtitle2 mt-3" style="color: rgb(189, 189, 189);">Te sugerimos una imagen de 250 x 250px</div>
              </div>
            </div>
          </label>
        </div>
        <div v-if="imgUploads" class="create-data-upload-img my-4 ">
          <div>
            <div id="imgupload"></div>
            <label for="cargararchivo" class="create-data-upload-edits">
              <div style="width: 100%; height: 100%;">Editar</div>
            </label>
            <div class="register-token-right-subtitle2 mt-3" style="color: rgb(189, 189, 189);">Te sugerimos una imagen de 250 x 250px</div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col">
            <div class="container-inputs-vuestic mt-4">
              <label>Nombre de la emisora</label>
              <va-input
                v-model="sdata.stationData.name"
                placeholder="Ingresa el nombre de tu emisora"
              />
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="d-flex align-items-center mt-1 mb-2" >
            <div class="col-6 gpr-back-info-title pr-4">
              <div class="container-inputs-vuestic2 position-relative mb-3">
                <label class="mt-3">Estado/Provincia/Región*</label>
                <div class="create-data-station-input4 create-data-100 create-data-pointer position-relative mt-2">
                  <div class="pt-1">
                    <div @click="setState(true)" class="text-start" :style="sdata.stationData.state !== '' ? 'color: #000000;' : ''">{{ sdata.stationData.state !== '' ? returAll(sdata.stationData.state, 3) : 'Selecciona una opción' }}</div>
                  </div>
                  <div class="create-data-station-w" style="top: 10px;" @click="setState(true)">
                    <img :src="ImgDown" alt="" />
                  </div>
                </div>
                <div v-if="showState" class="booking-campaing-location p-3" @mouseleave="setState(false)" style="top: 40px; min-width: 350px; height: 200px; overflow-y: auto; z-index: 1000;">
                  <div class="booking-campaing-height" style="height: 200px;">
                    <div v-for="(item, index) in states" :key="index" class="create-data-100 mt-3" style="height: auto;">
                      <div class="create-data-checkbox-text create-data-100">
                        <div :class="validateState(item.id) ? 'booking-campaing-range booking-campaing-range-selected create-data-pointer' : 'booking-campaing-range create-data-pointer'" @click="setStateId(item.id), setState(false)">{{ item.text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            <div class="col-6">
              <div class="container-inputs-vuestic2 position-relative mb-3">
                <label class="mt-3">Ciudad*</label>
                <div class="create-data-station-input4 create-data-100 create-data-pointer position-relative mt-2">
                  <div class="pt-1">
                    <div @click="setCiudad(true)" class="text-start" :style="sdata.stationData.city !== '' ? 'color: #000000;' : ''">{{ sdata.stationData.city !== '' ? returAll(sdata.stationData.city, 4) : 'Selecciona una opción' }}</div>
                  </div>
                  <div class="create-data-station-w" style="top: 10px;" @click="setCiudad(true)">
                    <img :src="ImgDown" alt="" />
                  </div>
                </div>
                <div v-if="showCiudad" class="booking-campaing-location p-3" @mouseleave="setCiudad(false)" style="top: 40px; min-width: 350px; height: 200px; overflow-y: auto; z-index: 1000;">
                  <div class="booking-campaing-height" style="height: 200px;">
                    <div v-for="(item, index) in city" :key="index" class="create-data-100 mt-3" style="height: auto;">
                      <div class="create-data-checkbox-text create-data-100">
                        <div :class="validateCiudad(item.id) ? 'booking-campaing-range booking-campaing-range-selected create-data-pointer' : 'booking-campaing-range create-data-pointer'" @click="setCiudadId(item.id), setCiudad(false)">{{ item.text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             </div>
          </div>
        </div>
        <div class="d-flex flex-row bd-highlight">
          <div class="create-data-radio-left">
            <div class="create-data-radio-title text-start">Selecciona tipo de radiofrecuencia</div>
            <div class="create-data-radio-subtitle text-start">Al seleccionar combo podrás asignar varias radiofrecuencias, ubicaciones y coberturas para esta emisora</div>
          </div>
          <div class="d-flex flex-row bd-highlight">
            <div class="create-data-radio-text">
              <label for="fm">FM</label><br>
              <input type="radio" id="fm" name="frecuencia" class="mt-2" @click="selectRadio(1)" :checked="sdata.stationData.radioChecked === 1 ? true : false">
            </div>
            <div class="create-data-radio-text">
              <label for="am">AM</label><br>
              <input type="radio" id="am" name="frecuencia" class="mt-2" @click="selectRadio(2)" :checked="sdata.stationData.radioChecked === 2 ? true : false">
            </div>
            <div class="create-data-radio-text">
              <label for="combo">Combo</label><br>
              <input type="radio" id="combo" name="frecuencia" class="mt-2" @click="selectRadio(3)" :checked="sdata.stationData.radioChecked === 3 ? true : false">
            </div>
          </div>
        </div>
        <div v-if="sdata.stationData.radioChecked === 1 || sdata.stationData.radioChecked === 3" class="d-flex flex-row bd-highlight align-items-center mt-4">
          <div class="create-data-station-radius">FM</div>
          <div class="create-data-station-131 ml-4">
            <div class="container-inputs-vuestic">
              <label class="text-start">Radiofrecuencia</label>
              <input type="text" v-model="sdata.stationData.fm.frecuencia" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="create-data-station-input mt-2" placeholder="00.00">
            </div>
          </div>
          <div class="create-data-station-131 ml-4">
            <div class="container-inputs-vuestic position-relative">
              <label class="text-start">Cobertura</label>
              <input type="text" v-model="sdata.stationData.fm.cobertura" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="create-data-station-input mt-2" placeholder="00">
              <div class="create-data-station-w">W</div>
            </div>
          </div>
          <div class="create-data-station-312 ml-4">
            <div class="container-inputs-vuestic position-relative">
              <label class="text-start">Siglas</label>
              <input type="text" v-model="sdata.stationData.fm.siglas" class="create-data-station-input create-data-station-input2 create-data-mayusculas mt-2" placeholder="Ingresa las siglas de tu emisora">
            </div>
          </div>
        </div>
        <div v-if="sdata.stationData.radioChecked === 2|| sdata.stationData.radioChecked === 3" class="d-flex flex-row bd-highlight align-items-center mt-4">
          <div class="create-data-station-radius">AM</div>
          <div class="create-data-station-131 ml-4">
            <div class="container-inputs-vuestic">
              <label class="text-start">Radiofrecuencia</label>
              <!-- <input type="text" v-model="sdata.stationData.am.frecuencia" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="create-data-station-input mt-2" placeholder="00.00"> -->
              <input type="text" v-model="sdata.stationData.am.frecuencia" class="create-data-station-input mt-2" placeholder="00.00">
            </div>
          </div>
          <div class="create-data-station-131 ml-4">
            <div class="container-inputs-vuestic position-relative">
              <label class="text-start">Cobertura</label>
              <!-- <input type="text" v-model="sdata.stationData.am.cobertura" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="create-data-station-input mt-2" placeholder="00"> -->
              <input type="text" v-model="sdata.stationData.am.cobertura" class="create-data-station-input mt-2" placeholder="00">
              <div class="create-data-station-w">W</div>
            </div>
          </div>
          <div class="create-data-station-312 ml-4">
            <div class="container-inputs-vuestic position-relative">
              <label class="text-start">Siglas</label>
              <input type="text" v-model="sdata.stationData.am.siglas" class="create-data-station-input create-data-station-input2 create-data-mayusculas mt-2" placeholder="Ingresa las siglas de tu emisora">
            </div>
          </div>
        </div>
        <div v-if="sdata.stationData.existAcronym">
          <label style="text-align:left;color: red;" for="Siglas">{{ sdata.stationData.messageAcronym }}</label>
        </div>
        <div class="d-flex flex-row bd-highlight">
          <div class="container-inputs-vuestic2 mt-4">
            <label class="text-start">Estilo musical</label>
            <div class="create-data-station-input4 create-data-100 create-data-pointer position-relative mt-2">
              <div>
                <div v-if="sdata.stationData.styleMusic.length === 0" @click="showModalStation(true)" class="text-start">Selecciona las opciones</div>
                <div v-if="sdata.stationData.styleMusic.length > 0" class="d-flex flex-row bd-highlight justify-content-start create-data-style-select2">
                  <div v-for="(item, index) in sdata.stationData.styleMusic.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))" :key="index" @click="setStyleMusic2(item.id)">
                    <div :class="returnStationSelected2(item.id) ? 'd-flex flex-row bd-highlight align-items-center created-data-style-selected create-data-pointer' : 'd-flex flex-row bd-highlight align-items-center create-data-pointer'">
                      <div>{{ item.name }}</div>
                      <img v-if="returnStationSelected2(item.id)" :src="ImgIcon" class="ml-3" alt="" width="25px" height="25px" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="create-data-station-w" style="top: 10px;" @click="showModalStation(true)">
                <img :src="ImgDown" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row bd-highlight">
          <div class="container-inputs-vuestic mt-4">
            <label class="mb-2">Breve descripción de tu emisora (Opcional)</label>
            <textarea class="create-data-station-input create-data-100 create-data-station-input3 mt-2" v-model="sdata.stationData.description" placeholder="Describe tu emisora"></textarea>
          </div>
        </div>
      </div>
    </va-form>
    <div class="row m-0 justify-content-center container-inputs-vuestic register-call-to-action-bottom2 text-center mb-0">
      <div>
        <button class="textgreen" @click="addEmisora(0)">Completar más tarde</button>
        <button :class="btnEnabled ? 'green ml-4' : 'disabled ml-4'" @click="btnEnabled ? addEmisora(1) : ''">Siguiente</button>
      </div>
    </div>
    <div v-if="modalStation" class="create-data-style-musical d-flex flex-row bd-highlight align-items-center justify-content-center">
      <div class="position-relative">
        <p>Selecciona el estilo músical de tu emisora</p>
        <div class="create-data-station-131 create-data-100">
          <div class="container-inputs-vuestic create-data-100 position-relative">
            <input type="text" v-model="search" class="create-data-station-input create-data-100 mt-2" placeholder="Buscar ...">
            <div class="create-data-station-w" style="top: 15px;">
              <img :src="ImgSearch" alt="" />
            </div>
          </div>
        </div>
        <div class="create-data-style-select">
          <div v-for="(item, index) in listStyles.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))" :key="index" @click="!returnStationSelected(item.musicStyleId) ? setStyleMusic(item.musicStyleId, item.name, true) : setStyleMusic(item.musicStyleId, item.name, false)">
            <div :class="returnStationSelected(item.musicStyleId) ? 'd-flex flex-row bd-highlight align-items-center created-data-style-selected create-data-pointer' : 'd-flex flex-row bd-highlight align-items-center create-data-pointer'">
              <div>{{ item.name }}</div>
              <img v-if="returnStationSelected(item.musicStyleId)" :src="ImgIcon" class="ml-3" alt="" />
            </div>
          </div>
        </div>
        <div class="row m-0 justify-content-center container-inputs-vuestic register-call-to-action-bottom3 text-center mb-0" style="position: absolute; border-radius: 8px;">
          <div class="create-data-100">
            <button class="textgreen create-data-100" @click="showModalStation(false)">Guardar Selección</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgClose from '@/assets/complete/cerrar.png'
import ImgHeader from '@/assets/addEmisora/Img.png'
import ImgEllipe7 from '@/assets/addEmisora/Ellipse7.png'
import ImgFile from '@/assets/logoRadiorama/upload.svg'
import ImgDown from '@/assets/addEmisora/chevron-down.png'
import ImgSearch from '@/assets/addEmisora/search-01.png'
import ImgIcon from '@/assets/logoRadiorama/xCircle.svg'
import { uploadLogo } from '@/services/register'
import { setDataEmisora, StyleMusic, updateStation } from '@/services/registerStation'
import { findStation, getCitiesbyState, getLocations } from '@/services/radio'
import { getStates } from '@/services/billing'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import router2 from '@/router'

export default {
  name: 'DataAnunciante',
  props: {
    setStateSteps: {
      type: Function
    }
  },
  setup (props) {
    const store = useStore()
    const sdata = store.state.station
    const router = useRoute()
    function setDataEmisoras (step) {
      const idrd = localStorage.getItem('idrd')
      const styleMusicTemp = []
      if (sdata.stationData.styleMusic.length > 0) {
        sdata.stationData.styleMusic.forEach(function (objs, index) {
          styleMusicTemp.push(objs.id)
        })
      }
      const objs = {
        name: sdata.stationData.name,
        img: sdata.stationData.img,
        city: sdata.stationData.city,
        state: sdata.stationData.state,
        styleMusic: JSON.stringify(styleMusicTemp),
        radioChecked: sdata.stationData.radioChecked,
        AMacronym: sdata.stationData.am.siglas,
        AMstation: sdata.stationData.am.frecuencia,
        AMcoverage: sdata.stationData.am.cobertura,
        FMacronym: sdata.stationData.fm.siglas,
        FMstation: sdata.stationData.fm.frecuencia,
        FMcoverage: sdata.stationData.fm.cobertura,
        description: sdata.stationData.description,
        radioGroupId: idrd,
        step: step
      }
      setDataEmisora(objs).then((response) => {
        sdata.stationData.idStation = response.data
        store.commit('addEmisora', sdata.stationData)
        if (step !== 0) {
          props.setStateSteps(3)
        } else {
          router2.push({ name: 'RadioListView' })
        }
      })
    }
    function updateDataEmisoras (id, step) {
      const idrd = localStorage.getItem('idrd')
      const styleMusicTemp = []
      if (sdata.stationData.styleMusic.length > 0) {
        sdata.stationData.styleMusic.forEach(function (objs, index) {
          styleMusicTemp.push(objs.id)
        })
      }
      const objs = {
        name: sdata.stationData.name,
        img: sdata.stationData.img,
        city: sdata.stationData.city,
        styleMusic: JSON.stringify(styleMusicTemp),
        radioChecked: sdata.stationData.radioChecked,
        AMacronym: sdata.stationData.am.siglas,
        AMstation: sdata.stationData.am.frecuencia,
        AMcoverage: sdata.stationData.am.cobertura,
        FMacronym: sdata.stationData.fm.siglas,
        FMstation: sdata.stationData.fm.frecuencia,
        FMcoverage: sdata.stationData.fm.cobertura,
        description: sdata.stationData.description,
        radioGroupId: idrd,
        radioStationId: id,
        step: step
      }
      updateStation(objs).then((response) => {
        sdata.stationData.idStation = id
        store.commit('addEmisora', sdata.stationData)
        if (step !== 0) {
          props.setStateSteps(3)
        } else {
          router2.push({ name: 'RadioListView' })
        }
      })
    }
    function addEmisora (step) {
      const stations = []
      if (sdata.stationData.am.siglas) {
        stations.push({ acronym: sdata.stationData.am.siglas, banda: 'AM' })
      }

      if (sdata.stationData.fm.siglas) {
        stations.push({ acronym: sdata.stationData.fm.siglas, banda: 'FM' })
      }

      const payload = {
        stations: stations,
        city: sdata.stationData.city
      }

      findStation(payload).then(response => {
        console.log(response.data)
        if (response.data.isOk) {
          console.log(response.data)
          sdata.stationData.existAcronym = false
          if (router.params.id === undefined) {
            setDataEmisoras(step)
          } else {
            updateDataEmisoras(router.params.id, step)
          }
        } else {
          sdata.stationData.existAcronym = true
          sdata.stationData.messageAcronym = response.data.message
        }
      })
    }
    return {
      sdata,
      addEmisora
    }
  },
  data () {
    return {
      city: [],
      states: [],
      showState: false,
      showCiudad: false,
      ImgClose: ImgClose,
      ImgHeader: ImgHeader,
      ImgEllipe7: ImgEllipe7,
      ImgSearch: ImgSearch,
      ImgFile: ImgFile,
      ImgIcon: ImgIcon,
      ImgDown: ImgDown,
      validation: null,
      showMessage: false,
      citys: [{
        id: 1,
        text: 'Ciudad de México'
      }, {
        id: 2,
        text: 'Monterrey'
      }],
      isPasswordVisible: false,
      listStyles: [],
      search: '',
      modalStation: false,
      styleMusicTemp: [],
      imgUploads: false
    }
  },
  created () {
    this.StyleMusics()
    this.GetStatess()
    this.getAllStates()
    if (this.sdata.stationData.img !== '' && this.sdata.stationData.img !== null) {
      this.imgUploads = true
    }
  },
  methods: {
    validateState (id) {
      if (this.sdata.stationData.state === id) {
        return true
      } else {
        return false
      }
    },
    setCiudad (value) {
      this.showCiudad = value
    },
    validateCiudad (id) {
      if (this.sdata.stationData.city === id) {
        return true
      } else {
        return false
      }
    },
    setCiudadId (id) {
      console.log('setCiudadId', id)
      this.sdata.stationData.city = id
    },
    returAll (id, type) {
      if (type === 4) {
        const index = this.city.findIndex(data => data.id === id)
        if (index !== -1) {
          return this.city[index].text
        }
      }
      if (type === 3) {
        const index = this.states.findIndex(data => data.id === id)
        if (index !== -1) {
          return this.states[index].text
        }
      }
    },
    setState (value) {
      this.showState = value
    },
    setStateId (id) {
      console.log('setStateId', id)
      this.sdata.stationData.state = id
      getCitiesbyState(id).then(response => {
        const arrayData = response.data
        const array2 = []
        for (const item of arrayData) {
          array2.push({
            id: item.locationsId,
            text: item.name
          })
        }
        this.city = array2
      })
    },
    getAllStates () {
      getStates().then(async response => {
        const arrayDataState = response.data
        for (const item of arrayDataState) {
          this.states.push({
            id: item.stateId,
            text: item.name
          })
        }
      })
    },
    GetStatess () {
      getLocations().then((response) => {
        this.citys = []
        this.citys = response.data
      })
    },
    StyleMusics () {
      StyleMusic().then((response) => {
        this.listStyles = []
        this.listStyles = response.data.resources
      })
    },
    showImageFile () {
      const archivo = document.getElementById('cargararchivo').files[0]
      const reader = new FileReader()
      this.sdata.stationData.img = ''
      if (archivo) {
        reader.readAsDataURL(archivo)
        const idrd = localStorage.getItem('idrd')
        const typerd = localStorage.getItem('typerd')
        const imagefile = document.getElementById('cargararchivo').files[0]
        const formData = new FormData()
        formData.append('file', imagefile)
        formData.append('client', idrd)
        formData.append('id', idrd)
        formData.append('type', typerd)
        const vm = this
        this.imgUploads = true
        reader.onloadend = function () {
          document.getElementById('imgupload').setAttribute('style', 'background: url(' + reader.result + ') center center/cover no-repeat #FFFFFF;')
        }
        uploadLogo(formData).then((response) => {
          if (response) {
            vm.sdata.stationData.img = response.data.location
          }
        }).catch((e) => { })
      }
    },
    showModalStation (value) {
      this.modalStation = value
      if (value) {
        this.styleMusicTemp = []
        const vm = this
        this.sdata.stationData.styleMusic.filter(function (objs) {
          vm.styleMusicTemp.push(objs)
          return true
        })
      } else {
        this.sdata.stationData.styleMusic = []
        const vm = this
        this.styleMusicTemp.filter(function (objs) {
          vm.sdata.stationData.styleMusic.push(objs)
          return true
        })
      }
    },
    setStyleMusic2 (id) {
      const index = this.sdata.stationData.styleMusic.findIndex(obj => obj.id === id)
      this.sdata.stationData.styleMusic.splice(index, 1)
    },
    setStyleMusic (id, name, validator) {
      if (validator) {
        this.styleMusicTemp.push({ id: id, name: name })
      } else {
        const index = this.styleMusicTemp.findIndex(obj => obj.id === id)
        this.styleMusicTemp.splice(index, 1)
      }
    },
    returnStationSelected2 (id) {
      const index2 = this.sdata.stationData.styleMusic.findIndex(obj => obj.id === id)
      if (index2 !== -1) {
        return true
      }
      return false
    },
    returnStationSelected (id) {
      const index2 = this.styleMusicTemp.findIndex(obj => obj.id === id)
      if (index2 !== -1) {
        return true
      }
      return false
    },
    selectRadio (value) {
      if (value === 1) {
        this.sdata.stationData.am.siglas = ''
        this.sdata.stationData.am.frecuencia = ''
        this.sdata.stationData.am.cobertura = ''
      } else if (value === 2) {
        this.sdata.stationData.fm.siglas = ''
        this.sdata.stationData.fm.frecuencia = ''
        this.sdata.stationData.fm.cobertura = ''
      }
      this.sdata.stationData.radioChecked = value
    }
  },
  mounted () {
    /* if (this.sdata.stationData.img !== '' && this.sdata.stationData.img !== null) {
      this.imgUploads = true
      const vm = this
      setTimeout(function () {
        document.getElementById('imgupload').setAttribute('style', 'background: url(' + vm.sdata.stationData.img + ') center center/cover no-repeat #FFFFFF;')
      }, 500)
    } */
  },
  components: {
  },
  computed: {
    btnEnabled () {
      if (this.sdata.stationData.name !== '' && this.sdata.stationData.city !== '' && this.sdata.stationData.radioChecked !== 0 && this.sdata.stationData.radioChecked) {
        if (this.sdata.stationData.radioChecked === 1) {
          if (this.sdata.stationData.fm.frecuencia !== '' && this.sdata.stationData.fm.cobertura !== '' && this.sdata.stationData.fm.siglas !== '') {
            if (this.sdata.stationData.styleMusic.length > 0) {
              return true
            }
          }
        } else if (this.sdata.stationData.radioChecked === 2) {
          if (this.sdata.stationData.am.frecuencia !== '' && this.sdata.stationData.am.cobertura !== '' && this.sdata.stationData.am.siglas !== '') {
            if (this.sdata.stationData.styleMusic.length > 0) {
              return true
            }
          }
        } else if (this.sdata.stationData.radioChecked === 3) {
          if (this.sdata.stationData.fm.frecuencia !== '' && this.sdata.stationData.fm.cobertura !== '' && this.sdata.stationData.fm.siglas !== '' && this.sdata.stationData.am.frecuencia !== '' && this.sdata.stationData.am.cobertura !== '' && this.sdata.stationData.am.siglas !== '') {
            if (this.sdata.stationData.styleMusic.length > 0) {
              return true
            }
          }
        }
      }
      return false
    }
  }
}
</script>
