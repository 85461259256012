<template>
  <div class="register-back-finish">
    <div class="register-container-finish align-items-center justify-content-center" :style="'width: 100%; height: 100%; background: url(' + ImgFinish + ') center top/cover no-repeat #FFFFFF;'">
      <div class="register-container-finish mb-5 pb-5">
        <div class="register-ondas-lateral">
          <img :src="OndasLeft" alt="" />
        </div>
        <div class="register-max-finish">
          <div class="d-flex justify-content-center w-100 align-center mb-5">
            <img :src="LogoFinish" alt="" width=400rem height="auto" />
          </div>
          <div class="mt-5">
            <div class="register-title-finish mt-5">Tu solicitud ha sido enviada.</div>
            <div class="register-subtitle-finish mt-3">En un lapso no mayor a 48 horas el equipo de Radiorama se pondrá en contacto contigo.</div>
            <div class="container-inputs-vuestic text-center">
              <RouterLink :to="{name: 'LoginView'}" type="button" class="green mt-4">Ir a Login</RouterLink>
            </div>
            <!--div class="register-subtitle-finish mt-3" style="font-size: small; color: rgb(103, 108, 112);">Si no recibiste correo de confirmación de registro, por favor contáctanos vía mail a <span style="color: rgb(85, 172, 160);">mangeles@radiodesk.com.mx</span></div-->
          </div>
        </div>
        <div class="register-ondas-lateral">
          <img :src="OndasRight" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgFinish from '@/assets/logoRadiorama/BGFondoBlanco.png'
/* import LogoFinish from '@/assets/register/logo_finish.svg' */
import ondasLeft from '@/assets/logoRadiorama/ondasLeft.svg'
import ondasRight from '@/assets/logoRadiorama/ondasRight.svg'

export default {
  name: 'MessageFinish',
  props: {
    emailData: {
      type: String
    }
  },
  data () {
    return {
      ImgFinish: ImgFinish,
      LogoFinish: 'https://assets-radiorama.s3.us-east-1.amazonaws.com/Radiorama.webp',
      OndasLeft: ondasLeft,
      OndasRight: ondasRight
    }
  },
  created () {
  },
  methods: {
  },
  mounted () {
  },
  components: {
  }
}
</script>
