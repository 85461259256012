
<template>
    <div class="row m-0 p-0">
      <div class="register-token-left create-account-left p-4 d-flex flex-column z-2">
        <svg class="position-absolute triangle-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="39"
          viewBox="0 0 32 39" fill="none">
          <path
            d="M29.7369 16.1311C32.195 17.7045 32.195 21.2955 29.7369 22.8689L6.65647 37.6429C3.99405 39.3471 0.500002 37.4351 0.500002 34.2739L0.500003 4.72608C0.500003 1.56493 3.99405 -0.347087 6.65647 1.35714L29.7369 16.1311Z"
            fill="black" />
        </svg>
        <div class="d-flex flex-column gap-3 mb-5">
          <div class="m-0 w-100">
            <!-- *closeIcon -->
            <CloseIcon class="close-icon" @click="closePage"></CloseIcon>
          </div>
          <div class="d-flex justify-content-center">
            <img :src="ImgLogo" alt="radiodesk logo" class="register-token-logo w-100"/>
          </div>
        </div>

<!-- *first step -->
        <div class="create-data-pointer mt-4 d-flex gap-2 align-items-center" @click="setStateSteps2(2)">
          <div
            class="icon-container"
            :class="{'active-step': (stateSteps + 1) === 2}"
          >
            <img :src="ImgData" alt="Icono" />
          </div>
          <div
            :class="(stateSteps + 1) === 2 ? 'register-token-left-title steps-texts ml-3' : 'create-account-left-title steps-texts-inactive ml-3'"
          >
          Datos de Anunciantes
          </div>
        </div>

        <!-- *second step -->
        <div class="create-data-pointer mt-4 d-flex gap-2 align-items-center" @click="setStateSteps2(3)">
          <div
            class="icon-container"
            :class="{'active-step': (stateSteps + 1) === 4}"
          >
            <img :src="ImgData" alt="Icono" />
          </div>
          <div
            :class="(stateSteps + 1) === 4 ? 'register-token-left-title steps-texts ml-3' : 'create-account-left-title steps-texts-inactive ml-3'"
          >
          Agregar Usuarios
          </div>
        </div>

        <!-- *third step -->
        <div class="create-data-pointer mt-4 d-flex gap-2 align-items-center" @click="setStateSteps2(4)">
          <div
            class="icon-container"
            :class="{'active-step': (stateSteps + 1) === 5}"
          >
            <img :src="ImgData" alt="Icono" />
          </div>
          <div
            :class="(stateSteps + 1) === 5 ? 'register-token-left-title steps-texts ml-3' : 'create-account-left-title steps-texts-inactive ml-3'"
          >
          Facturación
          </div>
        </div>

      </div>
      <div class="register-token-right register-token-right2 pt-0 pb-5">
        <div class="row m-0 justify-content-center">
          <div class="d-flex justify-content-center w-100">
            <data-advertiser v-if="stateSteps === 1 || stateSteps === 2" :setStateSteps="setStateSteps"></data-advertiser>
          </div>
        </div>
      </div>
      <add-users v-if="stateSteps === 3" :setStateSteps="setStateSteps"></add-users>
      <FacturaAnunciante v-if="stateSteps === 4" :billingId="billingId" @setDetailFacturas="setDetailFactura2" :hiddenModals="hiddenModal" @setFacturas="setFactura2"></FacturaAnunciante>
     <!--  <modal-confirmation v-if="stateSteps === 1" :setStateSteps="setStateSteps"></modal-confirmation> -->
      <div v-if="showDetailFactura" class="d-flex justify-content-end detail-anunciante-sidebar-silver" style="z-index: 10000000;">
        <div class="detail-anunciante-sidebar">
          <DetailFactura :clientId="idAdvertiser" @setFacturas="setFactura"></DetailFactura>
        </div>
      </div>
    </div>
</template>

<script>
import ImgClose from '@/assets/complete/cerrar.png'
import ImgLogo from '@/assets/logoRadiorama/RadioramaBlanco.png'
import ImgData from '@/assets/logoRadiorama/isotipo.svg'
import ImgDataSelect from '@/assets/complete/data.png'
/* import ModalConfirmation from '@/components/createAdvertiser/ModalConfirmation' */
import DataAdvertiser from '@/components/createAdvertiser/DataAdvertiser'
import AddUsers from '@/components/createAdvertiser/AddUsers'
import FacturaAnunciante from '@/components/createAdvertiser/FacturaAnunciante'
import DetailFactura from '@/components/createFactura/DetailFactura'
import CloseIcon from '@/components/icons/CloseIcon.vue'

export default {
  name: 'CreateAdvertiser',
  props: {
  },
  data () {
    return {
      ImgClose: ImgClose,
      ImgLogo: ImgLogo,
      ImgData: ImgData,
      ImgDataSelect: ImgDataSelect,
      billingId: 0,
      data: {
        name: ''
      },
      statusOption: 1,
      stateSteps: 1,
      valor1: 0,
      valor2: 0,
      valor3: 0,
      valor4: 0,
      showDetailFactura: false,
      hiddenModal: false,
      idAdvertiser: 0
    }
  },
  created () {
  },
  methods: {
    setFactura2 (value, id) {
      console.log('VIDENE DE DEATIL', id)
      this.billingId = id
      this.stateSteps = 0
      this.hiddenModal = value
      this.showDetailFactura = false
      const vm = this
      setTimeout(function () {
        vm.stateSteps = 4
      }, 100)
    },
    setFactura (value, id) {
      this.billingId = id.value
      console.log('VIDENE DE DEATIL', id.value)
      this.hiddenModal = value
      this.showDetailFactura = false
      this.stateSteps = 4
    },
    setDetailFactura2 (value, id) {
      this.stateSteps = 0
      this.idAdvertiser = id
      this.showDetailFactura = true
    },
    setDetailFactura (value) {
      this.showDetailFactura = value
    },
    setStateSteps2 (value) {
      if (this.valor1 === value) {
        this.stateSteps = value
      } else if (this.valor2 === value) {
        this.stateSteps = value
      } else if (this.valor3 === value) {
        this.stateSteps = value
      } else if (this.valor4 === value) {
        this.stateSteps = value
      }
    },
    setStateSteps (value) {
      this.stateSteps = value
      if (this.valor1 === 0) {
        this.valor1 = value
      } else if (this.valor2 === 0) {
        this.valor2 = value
      } else if (this.valor3 === 0) {
        this.valor3 = value
      } else if (this.valor4 === 0) {
        this.valor4 = value
      }
    },
    closePage () {
      this.$emit('close')
      setTimeout(() => {
        this.$router.push({ name: 'DashboardHomeAgencia' })
      }, 300)
    }
  },
  mounted () {
  },
  components: {
    /* ModalConfirmation: ModalConfirmation, */
    DataAdvertiser: DataAdvertiser,
    AddUsers: AddUsers,
    FacturaAnunciante: FacturaAnunciante,
    DetailFactura: DetailFactura,
    CloseIcon
  }
}
</script>
<style lang="scss" scoped>
@import "@/_scss/global/_variables.scss";

.icon-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.icon-container img {
  z-index: 2;
  width: 40px;
  height: 40px;
}

.icon-container.active-step {
  background: $color-gradient-two;
  border-radius: 50%;
  padding: 10px;
}

.close-icon {
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.2);
    }
}
.triangle-icon {
    right: -20px;
    top: 100px;
  }
.fonts {
  font-size: 16px;
}
.color-disabled {
  color: $color-nautral-50;
}
</style>
