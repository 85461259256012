<script setup>
import { reactive } from 'vue'
import AvatarIcon from '../icons/AvatarIcon.vue'
import AvatarIconInitials from '../icons/AvatarIconInitials.vue'
import { userRole } from '@/services/radioGruop'
import { useCookies } from 'vue3-cookies'
import { useRoute } from 'vue-router'

const { cookies } = useCookies()

const route = useRoute()

const routeAgencyList = '/agencia/list'
const routeGroupProfileAgency = '/agencia/profile'
const routeUserAgencyProfile = '/agencia/profile/user'

const userRoleInfo = reactive([])

const id = localStorage.getItem('idrd')
const type = localStorage.getItem('typerd')
userRole(id, type).then(response => {
  const role = response.data.role
  if (role) {
    userRoleInfo.push({
      nivel: role.displayName
    })
  }
})

const hiddenLogo = route.path !== '/agencia/register'

const logout = () => {
  if (cookies) {
    cookies.remove('token')
    localStorage.clear()
    location.reload('/login') // Redireccionar la página después del logout
  }
}
const rdspe = localStorage.getItem('rdspe') === 'A'
</script>
<template>
    <div class="btn-group dropdown-center">
        <button class=" button-config mx-4" type="button">
            <div class="profile-info">
                <p class="profile-info-name fw-medium">{{ data.name }}</p> <!-- Nombre -->
                <p v-if="userRoleInfo" class="profile-info-position">{{ userRoleInfo.length > 0 ? userRoleInfo[0].nivel : '' }}</p>
            </div>
        </button>
        <button type="button" class="btn btn-lg dropdown-toggle dropdown-toggle-split button-config" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="visually-hidden">Toggle Dropdown</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-dark ul-dropdown dropdown-center">
          <a :href="routeUserAgencyProfile" class="link-user-profile">
            <li class="d-flex justify-content-center my-4"><AvatarIconInitials :name="data.name"/></li>
            <li class="d-flex flex-column text-center">
                  <p class="profile-info-name">{{ data.name }}</p>
                  <p class="profile-info-position">{{ data.email }}</p>
            </li>
          </a>
            <li v-if="rdspe">
                <a :href="routeAgencyList">
                    <button class="create-campaing-button btn d-flex gap-2 align-items-center justify-content-center w-auto m-auto mt-5 text-nowrap">
                        Administrar Cuentas
                    </button>
                </a>
            </li>
            <li v-if="hiddenLogo">
                <a :href="routeGroupProfileAgency">
                    <button class="btn d-flex gap-2 align-items-center justify-content-evenly w-100 m-auto mt-5 text-nowrap bg-black">
                        <AvatarIcon v-if="hiddenLogo" class="avatar-sizing" :logo="data.logo"></AvatarIcon>
                        <div class="d-flex flex-column">
                            <p class="profile-info-name text-white fw-medium">{{ data.clientName }}</p>
                            <p class="profile-info-position text-white">Perfil</p>
                        </div>
                    </button>
                </a>
            </li>
            <li><hr class="dropdown-divider divider"></li>
            <li class="logout :hover"><button class="dropdown-item d-flex justify-content-center text-center" @click="logout">Cerrar Sesión</button></li>
        </ul>
    </div>
</template>
<script>

export default {
  name: 'DropdownUser',
  props: {
  },
  data () {
    return {
      data: {
        name: '',
        email: '',
        logo: null,
        clientName: ''
      }
    }
  },
  created () {
    localStorage.setItem('showvalue', false)
    /* bring user data */
    const storedUsername = localStorage.getItem('name')
    if (storedUsername) {
      this.data.name = storedUsername
    }
    const storedEmail = localStorage.getItem('email')
    if (storedEmail) {
      this.data.email = storedEmail
    }
    const storedLogo = localStorage.getItem('logo')
    if (storedLogo) {
      this.data.logo = storedLogo
    } else {
      this.data.logo = require('@/assets/user.png')
    }
    const storeClientName = localStorage.getItem('clientName')
    if (storeClientName) {
      this.data.clientName = storeClientName
    }
  },
  methods: {
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/_scss/global/_variables.scss";
.ul-dropdown {
    background-color: $color-neutral-80;
    margin: 2rem;
    padding: 2rem;
}
.button-config {
    background-color: transparent;
    border: 0cap;
    width: max-content;
}
.profile {
    display: flex;
    align-items: center;
    gap: 20px;

    .profile-info-name {
        font-family: Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
    }

    .profile-info-position {
        font-family: Helvetica;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
    }

    .profile-menu-icon {
        margin-left: 10px;
        cursor: pointer;
    }
}
.create-campaing-button {
    color: $color-primary-white;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    border-radius: 30px;
    padding: 8px 16px;
    background: $color-gradient-four;

    &:hover {
      background: $color-gradient-two;
    }
  }
.divider {
   background-color: $color-neutral-60;
}
.button-info {
    color: $color-primary-white;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    border-radius: 10px;
    padding: 8px 16px;
    &:hover {
        background: $color-nautral-50;
    }
  }
  .avatar-sizing {
    transform: scale(0.7);
  }

.logout {
    font-weight: 400;
    border-radius: 10px;
}

.link-user-profile {
  color: $color-primary-white;
  cursor: pointer;
  transition: all;
  &:hover {
    color: $color-secondary-02;
    transition: 0.4s ease-in-out;
  }
}

</style>
