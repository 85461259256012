<template>
  <div class="row m-0 p-0">
    <div class="register-container-left">
      <div class="row m-0 justify-content-center">
        <div class="register-max-left w-100 pt-5 d-flex flex-column justify-content-center align-items-center gap-4">
          <img :src="logo" alt="logo" style="width: 23rem;" />
          <div class="register-container-title mt-5 pt-4">Bienvenido</div>
        </div>
      </div>
      <div class="register-img-frecuencia" :style="'background: url(' + frecuencia + ') center top/cover no-repeat #000000;'"></div>
    </div>
    <div class="register-container-right">
      <div class="position-relative">
        <div class="triangulo">
          <img :src="polygono" alt="" />
        </div>
      </div>
      <div class="row m-0 justify-content-center">
        <div class="register-max-right">
          <div class="register-right-title">Solicita una cuenta</div>
          <!-- *botones de tipo-usuario -->
          <div v-if="listOptions">
            <div class="register-right-subtitle mt-2">Radiorama la nueva forma de anunciarse en radio.</div>
            <button class="register-right-button register-right-bf" @click="changeValue(false, 1)" @mouseover="showAddUrl(false, 1)" @mouseleave="showAddUrl(true, 1)">
              Soy Grupo de Radio
              <img :src="imgButton.img1" alt="" />
            </button>
            <button class="register-right-button mt-4" @click="changeValue(false, 2)" @mouseover="showAddUrl(false, 2)" @mouseleave="showAddUrl(true, 2)">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Soy Agencia &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img :src="imgButton.img2" alt="" />
            </button>

          </div>
          <!-- *formulario -->
          <div v-if="!listOptions">
            <va-form ref="form" class="mt-3 login-width-300">
              <div class="container-inputs-vuestic">
                <label>Nombre Completo*</label>
                <va-input
                  v-model="register.name"
                  :rules="[(v) => v.length > 3 || 'Ingresa un nombre']"
                  placeholder="Nombre y Apellidos"
                />
              </div>
              <div class="container-inputs-vuestic">
                <label>Correo electrónico corporativo*</label>
                <va-input
                  v-model="register.email"
                  :rules='[(v) => v.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || "Ingresa un correo valido"]'
                  placeholder="correo@mail.com"
                  @focusout="validateEmail()"
                />
                <span class="text-danger" v-if="flagEmail">{{ messageErrorEmail }}</span>
              </div>
              <div class="container-inputs-vuestic">
                <label>Teléfono de contacto*</label>
                <div class="row m-0 align-items-center">
                  <div class="col-1">
                    +52
                  </div>
                  <div class="col-4">
                    <va-input
                      v-model="register.number"
                      :rules="[(v) => v.length > 9 || 'Ingresa un número valido']"
                      placeholder="Número de teléfono"
                    />
                  </div>
                </div>
              </div>
              <div class="container-inputs-vuestic">
                <label>Contraseña*</label>
                <va-input
                  :type="isPasswordVisible ? 'text' : 'password'"
                  v-model="register.password"
                  :rules="[(v) => v.length > 7 || 'Ingresa una contraseña minimo de 8 caracteres']"
                  placeholder="******"
                  >
                  <template #appendInner>
                    <va-icon
                      :name="isPasswordVisible ? '' : ''"
                      size="small"
                      color="--va-primary"
                      :class="isPasswordVisible ? 'register-img2': 'register-img1'"
                      @click="isPasswordVisible = !isPasswordVisible"
                    />
                  </template>
                </va-input>
                <div class="register-message-password pt-2">Crea una contraseña de 8 caracteres, sin espacios </div>
              </div>
              <div class="container-inputs-vuestic pt-4 pb-5">
                <label>Confirma contraseña*</label>
                <va-input
                  :type="isPasswordVisible2 ? 'text' : 'password'"
                  v-model="register.repitepassword"
                  placeholder="******"
                  >
                  <template #appendInner>
                    <va-icon
                      :name="isPasswordVisible2 ? '' : ''"
                      size="small"
                      color="--va-primary"
                      :class="isPasswordVisible2 ? 'register-img2': 'register-img1'"
                      @click="isPasswordVisible2 = !isPasswordVisible2"
                    />
                  </template>
                </va-input>
                <p v-if="returnPasword()" class="form-text error" style="padding-bottom: 15px !important; padding-top: 10px !important;">Las contraseñas no son iguales. Por favor revisa e intenta nuevamente.</p>
              </div>
              <!--
              <div class="form-check d-flex gap-3 space">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="acceptTerms">
                  <label class="form-check-label register-right-text-tyc fw-medium fs-6" for="flexCheckDefault">
                    Al crear una cuenta, acepta nuestros
                    <b><a href="#?">Términos y Condiciones</a></b>.
                  </label>
              </div>-->
              <div class="container-inputs-vuestic create-data-100">
                <button :class="btnEnabled ? 'green mt-5 create-data-100' : 'disabled mt-5 create-data-100'" @click="btnEnabled ? changeStatus(false, register, typeData) : ''">Aceptar y continuar</button>
              </div>
            </va-form>
          </div>
        </div>
      </div>
      <div :class="listOptions ? 'register-right-tyc' : 'register-right-tyc2'">
        <div class="row m-0 justify-content-center">
          <div class="register-max-right">
            <div class="register-right-text-login mt-2">Ya tengo una cuenta <a href="/login">Iniciar Sesión</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioramaBlanco from '../../assets/logoRadiorama/RadioramaBlanco.png'
import circles1 from '../../assets/logoRadiorama/circles1.svg'
import circles2 from '../../assets/logoRadiorama/circles2.svg'
/* import frecuencia from '@/assets/register/frecuencia.svg' */
import frecuencia from '@/assets/logoRadiorama/Ondas1.png'
import soyAnunciante from '@/assets/register/soy_anunciante.png'
import circles1hover from '../../assets/logoRadiorama/circles1hover.svg'
import circles2hover from '../../assets/logoRadiorama/circles2hover.svg'
import SoyAnuncianteHover from '@/assets/register/SoyAnuncianteHover.png'
import polygono from '@/assets/register/Polygon1.png'
import { ref } from 'vue'
import { validateEmailExist } from '@/services/register'

export default {
  name: 'RegisterView',
  props: {
    changeStatus: {
      type: Function
    }
  },
  setup (props) {
    const listOptions = ref(true)
    return {
      listOptions
    }
  },
  data () {
    return {
      logo: RadioramaBlanco,
      frecuencia: frecuencia,
      soyGrupo: circles1,
      soyAgencia: circles2,
      soyAnunciante: soyAnunciante,
      SoyGrupoHover: circles1hover,
      SoyAgenciaHover: circles2hover,
      SoyAnuncianteHover: SoyAnuncianteHover,
      polygono: polygono,
      messageErrorEmail: '',
      flagEmail: false,
      register: {
        name: '',
        email: '',
        lada: '+52',
        number: '',
        password: '',
        repitepassword: ''
      },
      validation: null,
      isPasswordVisible: false,
      isPasswordVisible2: false,
      imgButton: {
        img1: '',
        img2: '',
        img3: ''
      },
      typeData: 0,
      acceptTerms: true
    }
  },
  created () {
    this.imgButton.img1 = this.soyGrupo
    this.imgButton.img2 = this.soyAgencia
    this.imgButton.img3 = this.soyAnunciante
  },
  methods: {
    validateEmail () {
      if (this.register.email.length > 1) {
        validateEmailExist(this.register.email).then(response => {
          const validate = response.data
          if (parseInt(validate.data) === 1) {
            this.messageErrorEmail = 'El correo ya existe, favor de elegir otro'
            this.flagEmail = true
          } else {
            this.flagEmail = false
            this.messageErrorEmail = ''
          }
        })
      }
    },
    toggleAcceptance () {
      this.acceptTerms = !this.acceptTerms
    },
    showAddUrl (value, button) {
      if (value) {
        if (button === 1) {
          this.imgButton.img1 = this.soyGrupo
        } else if (button === 2) {
          this.imgButton.img2 = this.soyAgencia
        } else if (button === 3) {
          this.imgButton.img3 = this.soyAnunciante
        }
      } else {
        if (button === 1) {
          this.imgButton.img1 = this.SoyGrupoHover
        } else if (button === 2) {
          this.imgButton.img2 = this.SoyAgenciaHover
        } else if (button === 3) {
          this.imgButton.img3 = this.SoyAnuncianteHover
        }
      }
    },
    changeValue (value, type) {
      this.typeData = type
      this.listOptions = value
    },
    returnPasword () {
      if (this.register.password !== '' && this.register.password !== ' ' && this.register.repitepassword !== '' && this.register.repitepassword !== ' ') {
        if (this.register.password !== this.register.repitepassword) {
          return true
        }
      }
      return false
    }
  },
  mounted () {
  },
  components: {
  },
  computed: {
    btnEnabled () {
      if (this.register.name !== '' && this.register.name !== ' ' && !this.flagEmail) {
        if (this.register.name.length > 3) {
          if (this.register.email !== '' && this.register.email !== ' ') {
            if (this.register.number !== '' && this.register.number !== ' ') {
              if (this.register.number.length > 9) {
                if (this.register.password !== '' && this.register.password !== ' ') {
                  if (this.register.password.length > 7) {
                    if (this.register.repitepassword !== '' && this.register.repitepassword !== ' ') {
                      if (this.register.repitepassword.length > 7) {
                        if (this.register.password === this.register.repitepassword && this.acceptTerms) {
                          return true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/_scss/global/_variables.scss";
  .error{
    color: $color-error;
  }
  .space{
    padding-top: 40px;
    margin-top: 20px;
  }
</style>
