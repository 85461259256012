
<template>
  <div class="row m-0 p-0">
    <div class="register-token-left create-account-left p-4">
      <div class="d-flex flex-column gap-4">
        <div class="m-0 w-100">
          <CloseIcon @click="close" class="back-icon-menu" style="width: 20px;"></CloseIcon>
        </div>
        <a href="/anunciante/booking-campaign" class="d-flex justify-content-center">
          <img :src="ImgLogo" alt="" class="register-token-logo ml-4 my-4" />
        </a>
      </div>
      <div class="create-data-pointer mt-4 ml-2" @click="setStateSteps2(1)">
        <img :src="(stateSteps + 1) === 2 ? ImgDataSelect : ImgData" alt="" />
        <div :class="(stateSteps + 1) === 2 ? 'register-token-left-title ml-4' : 'create-account-left-title ml-4'">Datos de Anunciantes</div>
      </div>
      <div class="create-data-pointer mt-4 ml-2" @click="setStateSteps2(2)">
        <img :src="(stateSteps + 1) === 3 ? ImgDataSelect : ImgData" alt="" />
        <div :class="(stateSteps + 1) === 3 ? 'register-token-left-title ml-4' : 'create-account-left-title ml-4'">Agregar Usuarios</div>
      </div>
    </div>
    <div class="register-token-right register-token-right2 pt-0 pb-5">
      <div class="position-relative">
        <div class="triangulo2">
          <img :src="polygono" alt="" style="width: 45px; height: 60px;" />
        </div>
      </div>
      <div class="row m-0 justify-content-center d-flex justify-content-end">
        <div>
          <data-advertiser v-if="stateSteps === 1" :setStateSteps="setStateSteps"></data-advertiser>
        </div>
      </div>
    </div>
    <add-users v-if="stateSteps === 2" :setStateSteps="setStateSteps"></add-users>
  </div>
</template>

<script>
import ImgClose from '@/assets/complete/cerrar.png'
import ImgLogo from '@/assets/logoRadiorama/RadioramaBlanco.png'
import ImgData from '@/assets/addEmisora/isotipo-RD.png'
import polygono from '@/assets/register/Polygon1.png'
import ImgDataSelect from '@/assets/complete/data.png'
import DataAdvertiser from '@/components/createAccount/DataAdvertiser'
import AddUsers from '@/components/createAccount/AddUsers'
import CloseIcon from '@/components/icons/CloseIcon.vue'

export default {
  name: 'CreateAccount',
  props: {
  },
  data () {
    return {
      CloseIcon: CloseIcon,
      ImgClose: ImgClose,
      ImgLogo: ImgLogo,
      ImgData: ImgData,
      ImgDataSelect: ImgDataSelect,
      polygono: polygono,
      data: {
        name: ''
      },
      statusOption: 1,
      stateSteps: 1,
      valor1: 0,
      valor2: 0,
      valor3: 0,
      valor4: 0,
      showDetailFactura: false,
      hiddenModal: false
    }
  },
  created () {
  },
  methods: {
    setStateSteps2 (value) {
      if (this.valor1 === value) {
        this.stateSteps = value
      } else if (this.valor2 === value) {
        this.stateSteps = value
      } else if (this.valor3 === value) {
        this.stateSteps = value
      } else if (this.valor4 === value) {
        this.stateSteps = value
      }
    },
    setStateSteps (value) {
      this.stateSteps = value
      if (value === 3) {
        this.$router.push('/anunciante')
      }
      if (this.valor1 === 0) {
        this.valor1 = value
      } else if (this.valor2 === 0) {
        this.valor2 = value
      } else if (this.valor3 === 0) {
        this.valor3 = value
      } else if (this.valor4 === 0) {
        this.valor4 = value
      }
    },
    close () {
      window.location.href = '/'
    }
  },
  mounted () {
  },
  components: {
    DataAdvertiser: DataAdvertiser,
    AddUsers: AddUsers,
    CloseIcon: CloseIcon
  }
}
</script>
<style>
.back-icon-menu {
  height: 35px;
  width: 35px;
  position: absolute;
  cursor: pointer;
    &:hover{
      transition: transform 0.4s ease-in-out;
      transform: scale(80%);
    }
  }
</style>
