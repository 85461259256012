<script setup>
import ImgClose from '@/assets/logoRadiorama/close.svg'
import IsotipoRd from '@/assets/logoRadiorama/isotipo.svg'
/* import IsotipoRdSelected from '@/assets/detailAnunciante/isotipo-RD-selected.png' */
import CreateFactura from '@/components/createFactura/CreateFactura'
import UploadFilePdf from '@/components/createFactura/UploadFilePdf'
import FacturaVinculada from '@/components/createFactura/FacturaVinculada'
import { ref, defineEmits, defineProps } from 'vue'
// import { useRouter } from 'vue-router'

const props = defineProps({
  billingId: {
    type: Number
  },
  clientId: {
    type: Number,
    default: 0
  }
})

// const router = useRouter()
const billingId = ref(null)
const validatorShow = ref(1)
const valor1 = ref(1)
const valor2 = ref(0)
const setValidator = (value) => {
  if (valor1.value === value) {
    validatorShow.value = value
  } else if (valor2.value === value) {
    validatorShow.value = value
  }
}
const setStateSteps = (value, id) => {
  console.log('FACTURA setStateSteps', id)
  billingId.value = id
  validatorShow.value = value
  if (valor1.value === 0) {
    valor1.value = value
  } else if (valor2.value === 0) {
    valor2.value = value
  }
}
const detailFactura = ref(false)
const emit = defineEmits(['setFacturas'])
const setDetailFacturas = (value) => {
  console.log('FACTURA detailFactura', billingId)
  emit('setFacturas', value, billingId)
}

const redirectUser = () => {
  setDetailFacturas()
}
</script>

<template>
  <div style="height: 100%;">
    <div class="bg-image position-absolute"></div>
    <div class="p-3 position-relative" style="height: 100%;">
      <div class="d-flex align-items-center detail-anunciante-close">
        <img :src="ImgClose" class="create-data-pointer" width="43" height="43" @click="setDetailFacturas()" alt="close"  style="background: #FFFFFF; border-radius: 50%; padding: 10px; border: 2px solid #DB5B00;">
        <div class="create-data-title ml-3" style="font-size: 24px;">Facturación</div>
      </div>
      <div class="d-flex mt-3" style="height: calc(100% - 60px);">
        <div class="col-3 pl-3 pr-3 position-relative mr-3" style="height: 100%;">
          <ul class="mt-5 detail-anunciante-ul create-data-pointer">
            <!-- <li class="d-flex" @click="setValidator(1)"><img :src="(validatorShow + 1) === 2 ? IsotipoRdSelected : IsotipoRd" width="30" height="30" class="mr-2" alt="" /> Datos de Facturación</li> -->
            <li
              class="d-flex align-items-center mt-3"
              :key="step"
              @click="setValidator(1)"
              :class="{ selected: validatorShow === 1 }"
            >
              <div
                class="icon-container"
                :class="{ 'icon-selected': validatorShow === 1 }"
              >
                <img :src="IsotipoRd" class="svg-icon"
                alt="Isotipo"/>
              </div>
              <span class="ml-2">Datos de Facturación</span>
            </li>
            <!-- <li class="d-flex mt-3" @click="setValidator(2)"><img :src="(validatorShow + 1) === 3 ? IsotipoRdSelected : IsotipoRd" width="30" height="30" class="mr-2" alt="" />Documentos</li> -->
            <li
              class="d-flex align-items-center mt-3"
              :key="step"
              @click="setValidator(2)"
              :class="{ selected: validatorShow === 2 }"
            >
              <div
                class="icon-container"
                :class="{ 'icon-selected': validatorShow === 2 }"
              >
                <img :src="IsotipoRd" alt="" />
              </div>
              <span class="ml-2">Documentos</span>
            </li>
           <!--  <li v-if="detailFactura" class="d-flex mt-3" @click="setValidator(3)"><img :src="(validatorShow + 1) === 4 ? IsotipoRdSelected : IsotipoRd" width="30" height="30" class="mr-2" alt="" />Anunciantes Vinculados</li> -->
            <li
              v-if="detailFactura"
              class="d-flex align-items-center mt-3"
              :key="step"
              @click="setValidator(3)"
              :class="{ selected: validatorShow === 3 }"
            >
              <div
                class="icon-container"
                :class="{ 'icon-selected': validatorShow === 3 }"
              >
                <img :src="IsotipoRd" alt="" />
              </div>
              <span class="ml-2">Anunciantes Vinculados</span>
            </li>
          </ul>
          <div class="row m-0 justify-content-center container-inputs-vuestic position-fixed" style="left: -20%; bottom: 60px;">
            <button @click="redirectUser()" class="textgreen" style="width: 230px !important; height: 35px; line-height: 0px;">Completar más tarde</button>
          </div>
        </div>
        <div class="col-9 pr-5 ml-5" style="overflow-y: auto;">
          <CreateFactura :clientId="props.clientId" :billingId="props.billingId" v-if="validatorShow === 1" @setStateSteps="setStateSteps"></CreateFactura>
          <UploadFilePdf v-if="validatorShow === 2" @setDetailFacturas="setDetailFacturas"></UploadFilePdf>
          <FacturaVinculada v-if="validatorShow === 3"></FacturaVinculada>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/_scss/global/_variables.scss";
  .bg-image {
    top: 0px;
    background: url('../../assets/logoRadiorama/BGFondoBlanco.png') top left / cover no-repeat transparent;
    width: 80%;
    height: 100%;
    opacity: 0.6;
  }
  .icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-primary-white;
  border-radius: 50%;
  transition: background 0.3s ease;

  &.icon-selected {
    background: $color-gradient-four;
  }

  img {
    width: 40px;
    height: 40px;
  }
}
</style>
