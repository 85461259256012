<script setup>
import ImgAlta from '@/assets/profileRadio/file-02.png'
import ImgClose from '@/assets/logoRadiorama/close.svg'
import SearchIcon from '@/components/icons/SearchIcon.vue'
import TrashIcon from '@/assets/addEmisora/x-01.png'
import { ref, defineEmits, defineProps, reactive } from 'vue'
import { detailBilling, getListadoBilling, setBilling } from '@/services/billing'
import { useStore } from 'vuex'

const store = useStore()
const sdataBilling = store.state.billignDeatail
const sdata = store.state.userAdvertiser
const props = defineProps({
  data: {
    type: Array
  },
  billingId: {
    type: Number
  },
  hiddenModals: Boolean
})
const idrd = localStorage.getItem('idrd')
const typerd = localStorage.getItem('typerd')

let showDatas = false
if (props.hiddenModals !== undefined) {
  showDatas = ref(props.hiddenModals)
}
console.log('ESTOY EN FACTURA ANUNCIANTE create', props.billingId)
const dataFacturation = ref({
  name: '',
  razonsocial: '',
  sector: '',
  rfc: '',
  regimentFiscal: '',
  address: '',
  country: '',
  city: '',
  state: '',
  cp: '',
  nameBank: '',
  numberAccount: '',
  clabe: '',
  emailFactura: ''
})

const getDetail = (id) => {
  detailBilling(id).then(response => {
    const element = response.data
    sdataBilling.value = {
      razonsocial: element.razonSocial,
      name: element.nombreComercial,
      rfc: element.rfc,
      regimentFiscal: element.regimenFiscal,
      address: element.direccion,
      country: element.country.name,
      city: element.city.name,
      state: element.state.name,
      sector: element.industryId,
      cp: element.zipCode,
      nameBank: element.bancoNombre,
      numberAccount: element.Cuenta,
      clabe: element.bancoClabe,
      emailFactura: element.correoFacturacion,
      urlPDF: element.urlConstancia
    }
    dataFacturations.value = sdataBilling.value
    console.log('FACTURA dataFacturations', dataFacturations.value)
  })
}

if (props.billingId > 0) {
  getDetail(props.billingId)
}

const showData = ref(showDatas)
const showEdit = ref(false)
const city = [{
  id: 1,
  text: 'México'
}, {
  id: 2,
  text: 'Colombia'
}]

const listFacturation = reactive([])
const getListsBilling = (idrd, typerd) => {
  getListadoBilling(idrd, typerd).then((response) => {
    const arrayBilling = response.data
    for (const billing of arrayBilling) {
      listFacturation.push({
        id: billing.billingId,
        name: billing.razonSocial
      })
    }
    console.log(listFacturation.value)
  })
}
getListsBilling(idrd, typerd)

const listFacturations = ref(listFacturation)
const filter = ref('')
const showExistFactura = ref(false)
const dataFacturations = ref(dataFacturation)
const citys = ref(city)
const changeEdit = (uno, dos) => {
  showData.value = uno
  showEdit.value = dos
}
const cleanSearchFilter = () => {
  filter.value = ''
}
const setExistFactura = (value) => {
  showExistFactura.value = value
}
const emit = defineEmits(['setDetailFacturas', 'setFacturas'])
const setModal2 = () => {
  emit('setDetailFacturas', true, sdata.data.idAdvertiser)
}
const setDetailFacturas2 = (value, billing) => {
  setDetailFacturas(billing)
}

const setDetailFacturas = (id) => {
  const array = []
  array.push(sdata.data.idAdvertiser)
  const objsInfo2 = {
    clientId: array,
    billingId: id,
    type: typerd
  }
  setBilling(objsInfo2).then((response) => {
    getDetail(id)
    emit('setFacturas', true, id)
  })
}
const close = () => {
  window.location.href = '/agencia/booking-campaign'
}
</script>

<template>
  <div style="position: absolute; top: 80px; left: 28%; width: 70%; padding-bottom: 100px !important; z-index: 2000;">
    <div class="create-data-title text-start">Facturación</div>
    <div class="p-2 detail-anunciante-silver mt-4">
      <div class="gpr-back-facturacion-title">
        <div class="gpr-float-left p-2 pl-4 gpr-facturation-title">Datos de facturación</div>
        <div class="gpr-float-right p-2 pr-4">
          <div class="row m-0 justify-content-center container-inputs-vuestic">
            <div v-if="showData && !showEdit">
              <!--button class="whitetext create-data-100" @click="setModal2(true)"
                style="width: 180px !important; height: 40px; line-height: 0px;">Editar</button-->
            </div>
            <div v-if="!showData && showEdit">
              <button class="textgreen create-data-100" @click="changeEdit(true, false)"
                style="width: 180px !important; height: 40px; line-height: 0px;">Descartar</button>
              <button class="whitetext create-data-100 ml-3" @click="changeEdit(true, false)"
                style="width: 180px !important; height: 40px; line-height: 0px;">Guardar Cambios</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!showData && !showEdit" style="z-index: 2000;">
        <div class="gpr-facturation-text-big text-center mt-5" style="font-size: 20px; line-height: 28px;">Da de alta los
          datos de facturación de tu agencia</div>
        <div class="text-center gpr-facturation-text-medium mt-3" style="font-size: 16px; line-height: 22px;">Es necesario
          para la cuenta de agencia tener datos de facturación, esto te permitirá <br /> comenzar a pautar campañas</div>
        <div class="row m-0 justify-content-center container-inputs-vuestic mt-5">
          <div style="width: 573px;">
            <button class="green" style="width: 573px;" @click="setModal2(true)">
              <div class="d-flex align-items-center justify-content-center">
                Alta de Facturación
                <img :src="ImgAlta" class="ml-2" alt="">
              </div>
            </button>
            <button class="green mt-4" @click="setExistFactura(true)" style="width: 573px;">Asignar Datos de facturación
              existente</button>
          </div>
        </div>
      </div>
      <div v-if="showData && !showEdit" style="z-index: 2000;">
        <div class="mt-5 gpr-text-data-contact">
          <div class="gpr-text-title mt-3">Nombre comercial</div>
          <div>{{ dataFacturations.name }}</div>
          <div class="gpr-text-title mt-3">Razón Social</div>
          <div>{{ dataFacturations.razonsocial }}</div>
          <div class="gpr-text-title mt-3"></div>
          <div>{{ dataFacturations.rfc }}</div>
          <div class="gpr-text-title mt-3">Dirección</div>
          <div style="line-height: 20px;">{{ dataFacturations.address }}<br /> {{ dataFacturations.city }},  {{ dataFacturations.state }}</div>
          <div class="gpr-text-title mt-3">Correo</div>
          <div>{{ dataFacturations.emailFactura }}</div>
        </div>
        <div class="gpr-facturation-card-info mt-3 p-3" style="height: 230px;">
          <div class="gpr-text-data-contact">
            <div>Datos Bancarios</div>
            <div class="d-flex">
              <div class="col-7">
                <div class="gpr-text-title mt-3">Banco</div>
                <div>{{ dataFacturations.nameBank }}</div>
              </div>
              <div class="col-5">
                <div class="gpr-text-title mt-3">CLABE</div>
                <div>{{ dataFacturations.clabe }}</div>
              </div>
            </div>
            <div class="gpr-text-title mt-3">Número de cuenta</div>
            <div>{{ dataFacturations.numberAccount }}</div>
            <div class="gpr-text-title mt-3">Correo de facturación</div>
            <div>{{ dataFacturations.emailFactura }}</div>
          </div>
        </div>
      </div>
      <div v-if="!showData && showEdit" style="z-index: 2000;">
        <div>
          <div>
            <div>
              <div class="pl-5 pr-5">
                <div class="container-inputs-vuestic mt-4">
                  <label>Nombre comercial*</label>
                  <va-input v-model="dataFacturations.name" placeholder="Ingresa nombre comercial" class="mt-1" />
                </div>
                <div class="d-flex align-items-center">
                  <div class="col-6">
                    <div class="container-inputs-vuestic mt-2">
                      <div style="font-size: 18px; font-weight: 700;">Datos Fiscales</div>
                      <label class="mt-2">Registro Federal de Contribuyentes (RFC)*</label>
                      <va-input v-model="dataFacturations.rfc" placeholder="000000000000" class="mt-1" />
                    </div>
                  </div>
                  <div class="col-6 gpr-back-info-title pl-4 pr-4 mt-5">Admitimos números de Registro Federal de <br />
                    Contribuyentes (RFC) de 12 dígitos</div>
                </div>
                <div class="d-flex align-items-center mt-4">
                  <div class="col-6">
                    <div class="container-inputs-vuestic mt-2">
                      <div style="font-size: 18px; font-weight: 700;">Dirección comercial</div>
                      <label class="mt-2">Dirección *</label>
                      <va-input v-model="dataFacturations.address" placeholder="Calle, Núm Int, Núm Ext, Colonia"
                        class="mt-1" />
                    </div>
                  </div>
                  <div class="col-6 gpr-back-info-title pl-4 pr-4">
                    <div class="container-inputs-vuestic mt-4">
                      <label class="mt-3">País*</label>
                      <div id="ktrmyjknmryrty" class="mt-1">
                        <select name="select" v-model="dataFacturations.country" required
                          class="create-data-style-select2000">
                          <option value="" disabled selected hidden>Selecciona una país</option>
                          <option v-for="(item, index) in citys" :key="index" :value="item.id">{{ item.text }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center mt-2">
                  <div class="col-6">
                    <div class="container-inputs-vuestic">
                      <label>Ciudad*</label>
                      <div id="ktrmyjknmryrty" class="mt-1">
                        <select name="select" v-model="dataFacturations.city" required
                          class="create-data-style-select2000">
                          <option value="" disabled selected hidden>Selecciona una país</option>
                          <option v-for="(item, index) in citys" :key="index" :value="item.id">{{ item.text }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 gpr-back-info-title pl-4 pr-4">
                    <div class="container-inputs-vuestic">
                      <label>Estado*</label>
                      <div id="ktrmyjknmryrty" class="mt-1">
                        <select name="select" v-model="dataFacturations.state" required
                          class="create-data-style-select2000">
                          <option value="" disabled selected hidden>Selecciona una país</option>
                          <option v-for="(item, index) in citys" :key="index" :value="item.id">{{ item.text }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="col-6">
                    <div class="container-inputs-vuestic">
                      <label class="mt-2">Código Postal*</label>
                      <va-input v-model="dataFacturations.address" placeholder="Ingresa un código postal" class="mt-1" />
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="col-6">
                    <div class="container-inputs-vuestic mt-2">
                      <div style="font-size: 18px; font-weight: 700;">Datos Bancarios</div>
                      <label class="mt-2">Banco</label>
                      <va-input v-model="dataFacturations.nameBank" placeholder="Nombre del banco" class="mt-1" />
                    </div>
                  </div>
                  <div class="col-6 pl-4 pr-4">
                    <div class="container-inputs-vuestic mt-4">
                      <label class="mt-3">CLABE</label>
                      <va-input v-model="dataFacturations.clabe" placeholder="Cuenta clabe" class="mt-1" />
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="col-6">
                    <div class="container-inputs-vuestic mt-4">
                      <label class="mt-3">Número de cuenta</label>
                      <va-input v-model="dataFacturations.numberAccount" placeholder="Número de cuenta" class="mt-1" />
                    </div>
                  </div>
                  <div class="col-6 pl-4 pr-4">
                    <div class="container-inputs-vuestic mt-4">
                      <label class="mt-3">Correo de facturación</label>
                      <va-input v-model="dataFacturations.emailFactura" placeholder="Correo de facturación"
                        class="mt-1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showExistFactura" class="d-flex justify-content-end detail-anunciante-absolute" style="z-index: 3000;">
    <div>
      <div class="bg-image2 position-absolute"></div>
      <div class="position-relative p-3">
        <header class="d-flex align-items-center">
          <img :src="ImgClose" class="create-data-pointer" @click="setExistFactura(false)" width="48" height="48" alt="close"  style="background: #FFFFFF; border-radius: 50%; padding: 10px; border: 2px solid #DB5B00;"
             />
          <div class="ml-4">
            <div class="register-container-title" style="color: #000;">Asignar datos de facturación Existente</div>
            <div class="register-right-subtitle">Asigna los datos de facturación correspondientes a <b>{{ sdata.data.name }}</b>
            </div>
          </div>
        </header>
        <div class="container-inputs-vuestic search-container create-data-100 position-relative p-0 m-0">
          <va-input v-model="filter" placeholder="Ingresa el nombre del anunciante" />
          <SearchIcon v-if="filter.length === 0" class="search-icon" />
          <img v-else @click="cleanSearchFilter" :src="TrashIcon" role="button" class="trash-icon" />
        </div>
        <div class="gpr-back-facturacion-title mt-3">
          <div class="gpr-float-left p-2 pl-4 gpr-facturation-title">Razón Social</div>
        </div>
        <div class="p-3" style="background-color: #FFFFFF;">
          <div
            v-for="(item, index) in listFacturations.filter(data => !filter || data.name.toLowerCase().includes(filter.toLowerCase()))"
            :key="index" class="detail-anunciante-select-facturation mt-3">
            <div class="d-flex create-data-100">
              <div class="mt-2" style="width: calc(100% - 150px);">{{ item.name }}</div>
              <div class="row m-0 justify-content-center container-inputs-vuestic p-0 m-0" style="width: 150px">
                <button class="whitetext create-data-100" @click="setDetailFacturas2(true, item.id)"
                  style="width: 150px !important; height: 30px; line-height: 0px;">Asignar Datos</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showData"
    class="row m-0 justify-content-center container-inputs-vuestic register-call-to-action-bottom2 text-center mb-0"
    style="background-color: #FFFFFF !important; z-index: 1000000;">
    <div>
      <!--button class="textgreen">Completar más tarde</button-->
      <button @click="close" class="green ml-4">Terminar</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-container {
  width: 317px;

  .search-icon,
  .trash-icon {
    position: absolute;
    right: 14px;
    top: 6px;
  }
}
</style>
